.tabs {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;

    .tabs_wrapper {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: -16px;
        
        .tab {
            margin-right: 16px;
            margin-bottom: 16px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .tabs_content {
        margin-top: 32px;
        flex-grow: 1;
        overflow: auto;
    }
}

.tab {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Neue Haas Grotesk Display Pro;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    text-align: center;
    color: #131214;
    width: 69px;
    height: 77px;
    background: rgba(19, 18, 20, 0.04);
    backdrop-filter: blur(24px);
    border-radius: 8px;
    cursor: pointer;
    overflow: auto;

    .tab_icon {
        width: 32px;
        height: 32px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }

}

.tab.tab_active {
    width: 156px;
    position: relative;
    &::before {
        content: '';
        position: absolute;
        border-radius: 50%;
        width: 100%;
        height: 100%;
        background: linear-gradient(270deg, #A2C80C 10.65%, #66BC10 108.46%);
        opacity: 0.2;
        filter: blur(14px)
    }
}

@media screen and (min-width: 769px) {
    .tabs {
        .tabs_content {
            margin-top: 24px;
        }

        .tabs_wrapper {
            margin-bottom: -8px;

            .tab {
                width: 77px;
                margin-right: 8px;
                margin-bottom: 8px;
    
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    .tab {
        font-size: 18px;
        
        &.tab_active {
            width: 196px !important;  
        }
    }
}