.btn {
    display: inline-block;
    appearance: none;
    -webkit-appearance: none;
    border: 0;
    background: linear-gradient(270deg, #A2C80C 10.65%, #66BC10 108.46%);
    box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.0112458), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0161557), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.02), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.0238443), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0287542), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.04);
    border-radius: 27.5px;
    color: #fff;
    padding: 13px 54px;
    font-weight: 600;
    font-size: 16px;
    line-height: 180%;
    cursor: pointer;

    &.text {
        font-weight: 600;
        font-size: 16px;
        line-height: 180%;
        padding: 0;
        box-shadow: none;
        // background: -webkit-linear-gradient(#A2C80C, #66BC10);
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
        text-decoration: underline;
        background: transparent;
        color: #66BC10;

    }

    &.secondary {
        background: #fff;
        // background: -webkit-linear-gradient(#A2C80C, #66BC10);
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
        border: 2px solid #66BC10;
        padding: 13px 31px;
        color: #66BC10;

    }
}