.wrapper {
    display: none;
    position: relative;
    height: 49px;
    margin-bottom: 30px;
    border-radius: 24.5px;
    justify-content: space-between;
    align-items: center;
    background: rgba(19, 18, 20, 0.04);
    padding: 0 24px;
}

.bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 24px;
    overflow: hidden;

    &::before {
        content: '';
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(19, 18, 20, 0.04);
        backdrop-filter: blur(24px);
        -webkit-backdrop-filter: blur(24px);
        filter: blur(24px)
    }
}

.dot_wrapper {
    position: absolute;
    top: 20px;
    z-index: -1;
    display: flex;
    justify-content: center;
    transition: .6s ease-in-out;
    
    .dot {
        width: 50px;
        height: 50px;
        border-radius: 50px;
        background: linear-gradient(270deg, #A2C80C 10.65%, #66BC10 108.46%);
        transition: .6s ease-in-out;
    }

    &.square .dot {
        border-radius: 8px;
    }
}

.nav {
    display: flex;
    height: 100%;
    .item {
        z-index: 2;

        .item_link {
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            text-decoration: none;
            transition: .2s ease-in-out;
            color: rgba(19, 18, 20, .5);
            font-size: 16px;
            padding: 0 15px;
        }

        &.is_active .item_link,
        &.fake_active .item_link {
            // font-weight: 600;
            color: rgb(19, 18, 20);
            text-shadow: 0 0 .65px #333, 0 0 .65px #333;
        }
    }
}

@media screen and (min-width: 769px) {
    .wrapper {
        display: inline-flex;
        flex: 0 0 auto;
        margin: 4.44vh auto 6.67vh;
    }
}

@media screen and (min-width: 1025px) {
    
}