.textarea {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #C5C5C5;
    font-family: Inter;
    font-style: normal;

    .label {
        font-weight: 300;
        font-size: 16px;
        line-height: 180%;
        color: #7C7C7C;
    }

    .textarea_control {
        font-family: Inter;
        width: 100%;
        margin-top: 8px;
        display: block;
        outline: none;
        border: 0;
        resize: none;
        font-weight: 300;
        font-size: 16px;
        line-height: 180%;
        height: 53px;
        background: transparent;
    }
}