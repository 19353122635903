.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 32px 0 40px;

    .left_btns {
        display: none;
        z-index: 8000;
    }

    .logo {
        width: 118px;
        height: 36px;
        background: url('../assets/img/logo.svg') no-repeat;
        background-size: contain;
        cursor: pointer;
    }

    .nav {
        .controller {
            width: 32px;
            height: 32px;
            background: url('../assets/img/burger.svg') no-repeat;
            background-size: contain;
            background-position: center;
        }

       .menu {
            display: none;
            position: fixed;
            top: 68px;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #FEFFFA;
            z-index: 8000;
            padding: 40px 32px;
            overflow-y: auto;

            .btns {
                display: flex;
                flex-direction: column;
                margin-top: 40px;

                & > button:last-child {
                    margin-top: 24px;
                }
            }
       }

       .menu_items {
           a {
                appearance: none;
                -webkit-appearance: none;
                -moz-appearance: none;
                box-shadow: none;
                outline: none;
                display: block;
                font-family: Neue Haas Grotesk Display Pro;
                text-decoration: none;
                font-weight: 300;
                font-size: 20px;
                color: #131214;
                opacity: 0.5;
                padding: 0 32px;
                line-height: 36px;
                margin-bottom: 24px;

                &:last-child {
                    margin-bottom: 0;
                }

                &.active {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    background: rgba(19, 18, 20, 0.04);
                    border-radius: 34px;
                    opacity: 1;
                    font-weight: normal;
                    padding: 16px 32px;

                    &::after {
                        content: '';
                        display: block;
                        width: 16px;
                        height: 16px;
                        border-radius: 16px;
                        background: linear-gradient(270deg, #A2C80C 10.65%, #66BC10 108.46%);
                        filter: blur(3px);
                    }
                }
           }
       }

       &.is_open {
        .menu {
            display: block;
        }
            .controller {
                background: url('../assets/img/close.svg') no-repeat;
            }
        }
    }

    @media screen and (min-width: 769px) {
        margin: 5.67vh 0 8.44vh; // margin: 51px 0 76px;
        flex: 0 0 auto;

       .logo {
            width: 196px;
            height: 60px;
        }

       .nav {
           display: none;
        }

       .left_btns {
           display: flex;

           & > *:first-child {
               margin-right: 40px;
           }
       }
    }
}

.form {
    .input_form {
        margin-bottom: 30px;
    }
}