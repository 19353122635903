.wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0, .9);;
    z-index: 9999;

    display: flex;
    justify-content: center;
    align-items: center;
}

.inner {
    width: 90%;
    flex-shrink: 0;
    background-color: #fff;
    min-height: 10vh;
    overflow: hidden;
    border: 2px solid #66BC10;
    border-radius: 6px;
}

.header {
    position: relative;
    padding: 16px 20px;
    border-bottom: 1px solid rgba(162, 200, 12, 1);
    padding-left: 30px;

    .title {
        font-family: Neue Haas Grotesk Display Pro;
        font-weight: normal;
        font-size: 20px;
    }

    .modal_close {
        position: absolute;
        right: 5px;
        top: 5px;
        background: url('../assets/img/close.svg') no-repeat center;
        width: 25px;
        height: 25px;
        cursor: pointer;
    }
}

.body {
    padding: 35px 20px 16px;
}

@media screen and (min-width: 769px) {
    .inner {
        width: initial;
        min-width: 50%;
    }
}