@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600&display=swap');
@import './reset.css';
@import './pages.css';

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

* {
    /*FireFox*/
    scrollbar-width: none;
    /*IE10+*/
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

*::-webkit-scrollbar {
    /*Chrome, Safari, Edge*/
    display: none;
}

@font-face {
    font-family: Neue Haas Grotesk Display Pro;
    src: url('../fonts/NeueHaasDisplayMediu.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: Neue Haas Grotesk Display Pro;
    src: url('../fonts/NeueHaasDisplayLight.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

body {
    font-family: Inter;
    font-size: 16px;
    background-color: #FEFFFA;
    min-height: 100vh;
    max-height: 100vh;
    color: #131214;
}

.under_construction {
    font-size: 3vw;
    text-align: center;
    line-height: 100vh;
}

.title {
    font-family: Neue Haas Grotesk Display Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    line-height: 120%;
    letter-spacing: 2px;
}

.title .green {
    background: -webkit-linear-gradient(#A2C80C, #66BC10);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.text {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 180%;
    color: #7C7C7C;
}

.anim {
    animation: fadein .8s ease-in-out;
}

@keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.senfina-app {
    min-height: 100vh;
    padding: 0 24px 20px;
}

.form {
    width: 100%;
}

.form .form-input {
    margin-bottom: 32px;
}

.form .form-input:last-child {
    margin-bottom: 0;
}

.form-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 30px;
}

.form-footer > button {
    margin-top: 32px;
    width: 100%;
}

@media screen and (min-width: 769px) {
    body {
        background-image: url('../img/bg.png');
        background-size: cover;
        background-position: top left;
        background-repeat: no-repeat;
        background-color: #F8FEF4;
    }

    .senfina-app {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0 7.78vw;
        max-height: 100vh;
        min-height: 100vh;
        height: 100vh;
        overflow: hidden;
    }

    .content {
        height: 100%;
        flex: 0 1 auto;
        overflow: hidden;
    }

    .form-footer button {
        margin-top: 15px;
        width: auto;
    }
}

@media screen and (min-width: 1025px) {
    /* .content {
        max-height: 54.67vh;
    } */
}