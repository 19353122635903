.input {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #C5C5C5;

    .input_label {
        font-weight: 300;
        font-size: 16px;
        line-height: 180%;
        color: #7C7C7C;
        margin-right: 8px;
    }

    .input_control {
        font-family: Inter;
        display: block;
        width: 100%;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        border: none;
        outline: none;
        background-color: transparent;
        color: rgba(19,18,20,1);
        font-weight: 300;
        font-size: 16px;
        line-height: 180%;

    }
}