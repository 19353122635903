.layout-left {
    justify-content: flex-start;
}

.layout-tabs {
    margin-top: 48px;
}

.home .text {
    margin: 2.67vh 0 4.44vh;
}

.home .video-section {
    left: 20vw;
}

.home .video-section .video video {
    object-fit: cover;
}

.mobile-page-picture {
    max-width: 100%;
    width: 100%;
    height: 100%;
    margin: auto;
    margin-bottom: 32px;
}

/* Contact US*/

.contact {
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
}

.contact .address {
    font-weight: normal;
    font-size: 18px;
    line-height: 120%;
    color: #131214;
}

.contact .adress .bold {
    font-weight: 600;
    font-size: 18px;
    line-height: 120%;
    color: #131214;
    margin-bottom: 8px;
}

.contact .label-btn {
    font-weight: 600;
    font-size: 24px;
    line-height: 120%;
    margin-right: 2.43vw;
    color: #fff;
}

.contact .login-btn {
    display: none;
    justify-content: space-between;
    align-items: center;
    margin-top: 64px;
    background: linear-gradient(270deg, #A2C80C 10.65%, #66BC10 108.46%);
    padding: 16px 2.22vw;
    border-radius: 12px;
}

.contact .login-btn button {
    padding: 13px 64px;
}

.contact .login-btn.mobile button {
    width: 100%;
}

.contact .login-btn.mobile .label-btn {
    text-align: center;
    margin-bottom: 10px;
}

.login-btn.mobile {
    display: block;
}

.contact .layout-right {
    position: initial;
    margin-top: 50px;
    display: block;
    width: 100%;
}

.contact .layout-left {
    justify-content: flex-start;
    height: fit-content;
}

.contact .title, .contact .layout-left .text {
    margin-bottom: 24px;
}

.contact .layout-left .text:last-child{
    margin-bottom: 0;
}


@media screen and (min-width: 796px) {
    .contact {
        flex-direction: row;
        justify-content: space-between;
    }

    .contact .login-btn {
        display: flex;
        margin-top: 32px;
    }

    .contact .login-btn.mobile {
        display: none;
    }

    .contact .layout-left {
        justify-content: flex-start;
        width: 34.24vw;
        height: inherit;
        overflow-y: auto;
    }

    .contact .layout-right {
        margin-top: 0px;
        justify-content: flex-start;
        width: 34.16vw;
        max-width: 491px;
        max-height: 100%;
        overflow-y: auto;
    }
}

/* TECHNOLOGY */


@media screen and (min-width: 769px) {
    .technology .layout-left {
        padding-top: 60px;
    }

    .layout-tabs {
        margin-top: 40px;
    }
}

/*About*/

.about_team {
    display: none;
    margin-top: 35px;
}

.about_team.is_mobile{
    display: block;
}

.members_wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.team_member_wrapper {
    flex-shrink: 0;
    width: 50%;
    padding: 0 6px;
}

.team_member_wrapper .team_member {
    width: 100%;
    margin: 0 -6px 14px;
}

.team_member__photo {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.team_member_info {
    margin-top: 16px;
}

.team_member_info__name {
    font-weight: 600;
    font-size: 16px;
    line-height: 120%;
    color: #131214;
}

.team_member_info__position {
    margin-top: 5px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: rgba(19, 18, 20, .6);
}

.section_title {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 120%;
    margin: 58px 0 40px;
    color: #131214;
}


@media screen and (min-width: 577px) {
    .team_member_wrapper {
        width: 33.3333%;
    }

    .mobile-page-picture {
        display: none;
    }
}

@media screen and (min-width: 1025px) {
    .about_team.is_mobile {
        display: none;
    }

    .about_team {
        max-width: 100%;
        width: 100%;
        max-height: 100%;
        margin-top: 0;
        display: block;
        overflow-y: auto;
        padding-left: 8.8vw;
    }

    .members_wrapper {
        margin: 0 -9px -40px;
    }


    .team_member_wrapper,
    .team_member_wrapper .team_member {
        margin: 0;
        padding: 0;
    }

    .team_member_wrapper {
        width: 50%;
        flex-shrink: 0;
        margin-bottom: 40px;
    }

    .team_member_wrapper .team_member {
        padding: 0 9px;
    }

    .team_member__photo {
        width: 100%;
        height: 100%;
    }

    .team_member_info {
        margin-top: 24px;
    }

    .team_member_info .team_member_info__name {
        font-weight: 600;
        font-size: 24px;
        line-height: 120%;
    }
    .team_member_info .team_member_info__position {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
    }

    .section_title {
        font-weight: 600;
        font-size: 32px;
        line-height: 120%;
        margin: 80px 0 40px;
    }
}

