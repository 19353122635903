.wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;

    .left {
        flex-shrink: 0;
        height: 100%;
    }

    .left {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-grow: 1;
    }

    .right {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        display: none;

        &.fit_content {
            position: static;
            width: 100%;
            max-width: 40.97vw;
        }
    }

    .img {
        display: none;
        width: 100%;
        max-width: 100%;
        max-height: 54.77vh;
        object-fit: contain;
        object-position: right;
    }

    .picture {
        width: 100%;
        height: 100%;
        object-fit: contain;

        .anim {
            opacity: 0;
        }
    }

    .video {
        position: relative;
        width: 100%;
        height: 100%;
        z-index: -100;

        video {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            // visibility: hidden;
            // opacity: 0;
            display: none;

            &.video_active {
                // visibility: visible;
                // opacity: 1;
                display: block;
            }
        }
    }

    @media screen and (min-width: 1025px) {
        .left {
            width: 44.1vw;
            // justify-content: center;
        }

        .right {
            display: block;
            left: 52.1vw;
        }

        .left, .right {
            flex: 0 0 auto;
            overflow: hidden;
        }

        .img {
            display: block;
        }
    }
}